exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sb-009-sebenza-tsx": () => import("./../../../src/pages/SB009_Sebenza.tsx" /* webpackChunkName: "component---src-pages-sb-009-sebenza-tsx" */),
  "component---src-pages-sb-018-sebenza-tsx": () => import("./../../../src/pages/SB018_Sebenza.tsx" /* webpackChunkName: "component---src-pages-sb-018-sebenza-tsx" */),
  "component---src-pages-sb-019-sebenza-tsx": () => import("./../../../src/pages/SB019_Sebenza.tsx" /* webpackChunkName: "component---src-pages-sb-019-sebenza-tsx" */),
  "component---src-pages-ss-004-m-4-jam-tsx": () => import("./../../../src/pages/SS004_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-004-m-4-jam-tsx" */),
  "component---src-pages-ss-005-m-4-jam-tsx": () => import("./../../../src/pages/SS005_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-005-m-4-jam-tsx" */),
  "component---src-pages-ss-006-m-4-jam-tsx": () => import("./../../../src/pages/SS006_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-006-m-4-jam-tsx" */),
  "component---src-pages-ss-007-m-4-jam-tsx": () => import("./../../../src/pages/SS007_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-007-m-4-jam-tsx" */),
  "component---src-pages-ss-008-m-4-jam-tsx": () => import("./../../../src/pages/SS008_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-008-m-4-jam-tsx" */),
  "component---src-pages-ss-009-m-4-jam-tsx": () => import("./../../../src/pages/SS009_M4JAM.tsx" /* webpackChunkName: "component---src-pages-ss-009-m-4-jam-tsx" */),
  "component---src-pages-ss-011-metrofibre-tsx": () => import("./../../../src/pages/SS011_Metrofibre.tsx" /* webpackChunkName: "component---src-pages-ss-011-metrofibre-tsx" */),
  "component---src-pages-ss-012-metrofibre-tsx": () => import("./../../../src/pages/SS012_Metrofibre.tsx" /* webpackChunkName: "component---src-pages-ss-012-metrofibre-tsx" */),
  "component---src-pages-ss-013-metrofibre-tsx": () => import("./../../../src/pages/SS013_Metrofibre.tsx" /* webpackChunkName: "component---src-pages-ss-013-metrofibre-tsx" */),
  "component---src-pages-survey-finished-tsx": () => import("./../../../src/pages/survey/finished.tsx" /* webpackChunkName: "component---src-pages-survey-finished-tsx" */),
  "component---src-pages-survey-index-tsx": () => import("./../../../src/pages/survey/index.tsx" /* webpackChunkName: "component---src-pages-survey-index-tsx" */),
  "component---src-templates-survey-tsx": () => import("./../../../src/templates/survey.tsx" /* webpackChunkName: "component---src-templates-survey-tsx" */)
}

